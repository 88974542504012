<template>
<g-card page-icon="mdi-account-group" page-title="Client List">
  <g-list-page
      elevation="elevation-0"
      :headers="headers"
      :columns="columns"
      stateendpoint="client.clients"
      @loadData="loadData"
      @editItem="editItem"
      @deleteItem="deleteItem"
      @destroyItem="destroyItem"
      @restoreItem="restoreItem"
      @switchHandler="switchHandler"
  >
    <template v-slot:overlay>
      <page-overlay :overlay="isOverlay" />
    </template>
    <template v-slot:top>
      <v-row>
        <v-col cols="12" md="6" sm="8">
          <g-p-search
              hint="Minimum two characters needed to search. Columns(name)"
              @applySearch="handleSearch"
          />
        </v-col>
        <v-col cols="12" md="6" sm="4">
          <add-button
              text="Client"
              :toolbar="true"
              @onButtonClick="openPopUp"
              class="ma-2 justify-end"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="4" sm="12">
          <v-select
              v-model="currentPaging.type"
              label="Client Type"
              :items="clientTypes"
              item-text="text"
              item-value="value"
              dense
              outlined
              @change="onChangeClientType()"
          />
        </v-col>
      </v-row>
    </template>
  </g-list-page>
  <component
      is="ClientPopup"
      ref="component"
      :basic-data="basicData"
      :item="editedItem"
      :show="showPopup"
      @closePopupHandler="closePopupHandler"
      @onPopupShow="openPopUp"
      @saveItemHandler="saveItemHandler"
  />
  <confirm-popup
      :show="showConfirm"
      confirm-event="onConfirmItem"
      @closePopupHandler="showConfirm = false"
      @onConfirmItem="confirmEvent(currentSelectedItem, true)"
  >
    <div>
      <!-- eslint-disable-next-line  -->
      <span>{{ confirmTxt }}</span>
    </div>
  </confirm-popup>
</g-card>
</template>

<script>
import GCard from "../components/GCard";
import GListPage from "../components/list/GListPage";
import PageOverlay from "../components/PageOverlay";
import GPSearch from "../components/GPSearch";
import AddButton from "../components/buttons/AddButton";
import ListMixins from "../components/mixins/list/List";
import ConfirmPopup from "../components/popups/ConfirmPopup";
import ClientPopup from "../components/popups/ClientPopup";
export default {
  name: "ClientsPage",
  components: {
    ConfirmPopup,
    AddButton,
    GPSearch,
    PageOverlay,
    GListPage,
    GCard,
    ClientPopup
  },
  mixins: [ListMixins],
  created() {
    this.currentPaging.type = "all";
  },
  data() {
    return {
      basicData: {
        price: 0,
        linkPrice: 0,
        adultPrice: 0,
        type: 0,
        discount: 0,
      },
      actions: {
        load: "client/load",
        store: "client/store",
        update: "client/update",
        remove: "client/remove",
        destroy: "client/destroy",
      },
      columns: [
          {
            name: "price",
            callback: "getMoneySign"
          },
        {
          name: "linkPrice",
          callback: "getMoneySign"
        },
        {
          name: "discount",
          callback: "getPercentageSign"
        },
        {
          name: "country",
          callback: "getCountryName"
        },
      ],
      clientTypes: [
        {
          text: "All",
          value: "all"
        },
        {
          text: "Fix Clients",
          value: 2
        },
        {
          text: "Special Clients",
          value: 3
        },
        {
          text: "More Special Clients",
          value: 4
        },
        {
          text: "Agency Clients",
          value: 5
        },
        {
          text: "Buyers",
          value: 6
        },
      ],
      headers: [
        {
          text: "Email",
          value: "email"
        },
        {
          text: "Skype",
          value: "skype"
        },
        {
          text: "Whatsapp",
          value: "whatsapp"
        },
        {
          text: "Country",
          value: "country"
        },
        {
          text: "Address",
          value: "address"
        },
        {
          text: "Price",
          value: "price",
          align: "right"
        },
        {
          text: "Link Price",
          value: "linkPrice",
          align: "right"
        },
        {
          text: "Other Price",
          value: "adultPrice",
          align: "right"
        },
        {
          text: "Discount(%)",
          value: "discount",
          align: "right"
        },
        {
          text: "Status",
          value: "isDeleted",
          cellClass: "center-switch",
          align: "center"
        },
        {
          text: "",
          value: "actions",
          sortable: false
        }
      ]
    };
  },
  methods: {
    onChangeClientType() {
      this.loadData({isPagingReset: false})
    },
  },
}
</script>

<style scoped>

</style>